<template>
  <router-view />
</template>

<script setup>

</script>
<style>
/* Import the desired font */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

:root, :host {
  --va-font-family: 'Ubuntu';
}
</style>